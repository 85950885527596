<template>
  <v-content color="primary">
    <v-container fluid id="main-container" grid-list-md fill-height>
      <v-snackbar
          v-model="snackbar"
          :timeout="timeout"
          top
      >
        {{ t(text) }}
        <v-btn
            flat
            @click="snackbar = false"
        >
          {{ t('Close') }}
        </v-btn>
      </v-snackbar>
      <v-layout
          justify-center
          wrap
      >
        <v-flex sm8 xs12>
          <v-card
              class="mx-auto"
              :loading="loading"
          >
            <v-list two-line subheader dense>
              <v-subheader>{{ t('Details') }}
                <v-dialog v-model="deletePackageDialog" max-width="290">
                  <template v-if="canDelete" v-slot:activator="{ on }">
                    <v-btn color="error" v-on="on" small icon ripple class="ml-auto mr-0">
                      <v-icon small>delete</v-icon>
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-title class="headline">{{ t('Are you sure you want to delete?') }}</v-card-title>
                    <v-card-text>{{ t('This action cannot be undone') }}
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn small color="error" @click="deletePackageDialog = false">{{ t('Close') }}
                      </v-btn>
                      <v-btn outline small color="error" @click="deletePackage">{{ t('Delete') }}</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <!--<v-btn color="error" @click="deleteSubscriber" small icon ripple class="ml-auto mr-0">-->
                <!--<v-icon small>delete</v-icon>-->
                <!--</v-btn>-->
              </v-subheader>
              <v-list-tile
                  avatar
                  @click=""
              >
                <v-list-tile-avatar>
                  <v-icon class="grey lighten-1 white--text">archive</v-icon>
                </v-list-tile-avatar>

                <v-list-tile-content>
                  <template v-if="!headerEdit">
                    <v-list-tile-title>{{ t('Header') }}</v-list-tile-title>
                    <v-list-tile-sub-title>{{ details.header }}</v-list-tile-sub-title>
                  </template>
                  <template v-else>
                    <v-text-field 
                      :placeholder="t('Header')"
                      v-model="newHeader"
                    ></v-text-field>
                  </template>
                </v-list-tile-content>
                <v-list-tile-action>
                  <v-progress-circular
                    v-if="savingNewHeader"
                    indeterminate
                    color="primary"
                  ></v-progress-circular>
                  <template v-else>
                    <v-btn
                      v-if="!headerEdit"
                      @click="headerEdit = true"
                      color="success"
                      icon
                      ripple
                      small
                      dark
                    >
                      <v-icon small>edit</v-icon>
                    </v-btn>
                    <v-layout v-else>
                      <v-flex>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <v-btn
                              v-on="on"
                              :to="a"
                              v-if="headerEdit"
                              @click="saveNewHeader"
                              color="success"
                              icon
                              ripple
                              small
                              dark
                            >
                              <v-icon small>done</v-icon>
                            </v-btn>
                          </template>
                          <span>{{ t('Save') }}</span>
                        </v-tooltip>
                      </v-flex>
                      <v-flex>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <v-btn
                              v-on="on"
                              v-if="headerEdit"
                              @click="newHeader = '';headerEdit = false"
                              color="error"
                              icon
                              ripple
                              small
                              dark
                            >
                              <v-icon small>close</v-icon>
                            </v-btn>
                          </template>
                          <span>{{ t('Close') }}</span>
                        </v-tooltip>
                      </v-flex>
                    </v-layout>
                  </template>
                </v-list-tile-action>
              </v-list-tile>

              <v-list-tile
                  avatar
                  @click=""
              >
                <v-list-tile-avatar>
                  <v-icon class="grey lighten-1 white--text">tv_off</v-icon>
                </v-list-tile-avatar>
                <v-list-tile-content>
                  <v-list-tile-title>{{ t('Age limit') }}</v-list-tile-title>
                  <v-list-tile-sub-title>{{ details.age_limit }}</v-list-tile-sub-title>
                </v-list-tile-content>
              </v-list-tile>
              <v-list-tile
                  avatar
                  @click=""
                  v-if="details.cards"
              >
                <v-list-tile-avatar>
                  <v-icon class="grey lighten-1 white--text">card_membership</v-icon>
                </v-list-tile-avatar>
                <v-list-tile-content>
                  <v-list-tile-title>{{ t('Total cards') }}</v-list-tile-title>
                  <v-list-tile-sub-title>{{ details.cards }}</v-list-tile-sub-title>
                </v-list-tile-content>
              </v-list-tile>
            </v-list>
          </v-card>
          <v-card class="mt-3">
            <v-subheader>{{ t('Tariffs') }}</v-subheader>
            <v-data-table
                :headers="tariff_headers"
                :items="tariffs"
                class="elevation-1"
                :loading="loadingTariffs"
                :search="search"
                hide-actions
            >
              <template slot="items" slot-scope="props">
                <td class="text-xs-left no-wrap">{{ props.item.payment_period.name }}</td>
                <td class="text-xs-left no-wrap">{{ props.item.payment_period.kind_period }}</td>
                <td class="text-xs-left no-wrap">{{ props.item.tariff + $store.getters.currency }}</td>
                <td class="text-xs-left no-wrap">{{ props.item.payment_period.quantity }}</td>
                <td class="text-xs-left no-wrap">
                  <v-tooltip v-if="canDelete" bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn v-on="on" color="success" @click="showSubmitForm(props.item.payment_period.id, props.item.tariff, props.item.only_admins)" ripple
                             icon
                             small dark>
                        <v-icon small>edit</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ t('Edit') }}</span>
                  </v-tooltip>
                  <v-tooltip v-if="canDelete" bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn v-on="on" color="error" @click="showRemovalWarning(props.item.id)" ripple
                             icon
                             small dark>
                        <v-icon small>delete</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ t('Remove') }}</span>
                  </v-tooltip>
                </td>
              </template>
              <template slot="no-data">
                <v-subheader>{{ t('No data available') }}</v-subheader>
              </template>
            </v-data-table>
          </v-card>
          <v-card class="mt-3">
            <v-subheader>{{ t('Payment periods') }}</v-subheader>
            <v-data-table
                :headers="payment_periods_headers"
                :items="availablePeriods"
                class="elevation-1"
                :loading="loadingPeriods"
                :search="search"
                hide-actions
            >
              <template slot="items" slot-scope="props">
                <td class="text-xs-left no-wrap">{{ props.item.name }}</td>
                <td class="text-xs-left no-wrap">{{ props.item.kind_period }}</td>
                <td class="text-xs-left no-wrap">{{ props.item.quantity }}</td>
                <td class="text-xs-left no-wrap">
                  <v-tooltip v-if="canDelete" bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn v-on="on" @click="showSubmitForm(props.item.id)" color="success"
                             ripple
                             icon small dark>
                        <v-icon small>add</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ t('Add') }}</span>
                  </v-tooltip>
                </td>
              </template>
              <template slot="no-data">
                <v-subheader>{{ t('No data available') }}</v-subheader>
              </template>
            </v-data-table>
          </v-card>
        </v-flex>

        <v-flex sm4 xs12>
          <v-card class="mb-3">
            <v-card-title>
              <v-text-field
                  v-model="search"
                  append-icon="search"
                  :label="t('Search')"
                  single-line
                  hide-details
              >
              </v-text-field>
            </v-card-title>
          </v-card>
          <v-card class="my-3">
            <v-subheader>{{ t('Bouquets') }}</v-subheader>
            <v-data-table
                :headers="headers"
                :items="bouquets"
                items-per-page="5"
                class="elevation-1"
                :loading="loadingbouquets"
                :search="search"
                :rows-per-page-text="t('Rows per page')"
                :rows-per-page-items='[10,25, 50, {text: $translate.locale["All"], value: -1}]'
            >
              <template slot="items" slot-scope="props">
                <td class="text-xs-left no-wrap">{{ props.item.name }}</td>
                <td class="text-xs-left no-wrap">{{ props.item.age_limit }}</td>
                <td class="text-xs-left no-wrap">
                  <!--<v-tooltip bottom>-->
                  <!--<template v-slot:activator="{ on }">-->
                  <!--<v-btn v-on="on" :to="`/subscribers/${props.item.pk}/details/`" color="info"-->
                  <!--ripple icon small dark>-->
                  <!--<v-icon small>info</v-icon>-->
                  <!--</v-btn>-->
                  <!--</template>-->
                  <!--<span>Detail</span>-->
                  <!--</v-tooltip>-->
                  <v-tooltip v-if="canDelete" bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn v-on="on" color="error" @click="removeBouquet(props.item.number)"
                             ripple
                             icon small dark>
                        <v-icon small>close</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ t('Remove') }}</span>
                  </v-tooltip>
                  <h5 v-else>-</h5>
                </td>
              </template>
              <template slot="pageText" slot-scope="item">
                {{ t('Elements') }} {{ item.pageStart }} - {{ item.pageStop }}, {{ t('total') }}: {{ item.itemsLength }}
              </template>
              <template slot="no-data">
                <v-subheader>{{ t('No data available') }}</v-subheader>
              </template>
            </v-data-table>
          </v-card>
          <v-card class="my-3">
            <v-data-table
                :headers="headers"
                :items="availableBouquets"
                items-per-page="5"
                class="elevation-1"
                :loading="loadingAvailableBouquets"
                :search="search"
                v-if="canDelete"
                :rows-per-page-text="t('Rows per page')"
                :rows-per-page-items='[10,25, 50, {text: $translate.locale["All"], value: -1}]'
            >
              <template slot="items" slot-scope="props">
                <td class="text-xs-left no-wrap">{{ props.item.name }}</td>
                <td class="text-xs-left no-wrap">{{ props.item.age_limit }}</td>
                <td class="text-xs-left no-wrap">
                  <v-tooltip v-if="canDelete" bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn v-on="on" @click="addBouquet(props.item.number)" color="success"
                             ripple icon small
                             dark>
                        <v-icon small>add</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ t('Add') }}</span>
                  </v-tooltip>
                </td>
              </template>
              <template slot="pageText" slot-scope="item">
                {{ t('Elements') }} {{ item.pageStart }} - {{ item.pageStop }}, {{ t('total') }}: {{ item.itemsLength }}
              </template>
              <template slot="no-data">
                <v-subheader>{{ t('No data available') }}</v-subheader>
              </template>
            </v-data-table>
          </v-card>
        </v-flex>
      </v-layout>
      <v-dialog v-model="deleteDialog" max-width="290">
        <v-card>
          <v-card-title class="headline">{{ t('Are you sure you want to delete?') }}
          </v-card-title>
          <v-card-text>
            {{ t('This action cannot be undone') }}
          </v-card-text>
          <v-card-actions>
            <v-spacer>
            </v-spacer>
            <v-btn small color="error" @click="closeRemovalWarning">{{ t('Close') }}
            </v-btn>
            <v-btn outline small color="error" @click="removeTariff">
              {{ t('Delete') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialog" max-width="600px">
        <v-card>
          <v-card-title>
            <span class="headline">{{ t('Payment period') }}</span>
          </v-card-title>
          <v-card-text>
            <v-container grid-list-md>
              <v-form
                  ref="form"
              >
                <v-layout wrap>

                  <v-flex xs12 sm12>
                    <v-text-field :label="t('Tariff')+'*'" v-model="tariff"
                                  :rules="[rules.required, rules.counterTariff]">
                    </v-text-field>
                    <v-checkbox :label="t('Only for admins')" v-model="onlyAdmins"></v-checkbox>
                  </v-flex>
                </v-layout>
              </v-form>
            </v-container>
            <small>{{ t('*indicates required field') }}</small>
          </v-card-text>
          <v-card-actions>
            <v-spacer>
            </v-spacer>
            <v-btn color="primary" text @click="closeSubmitForm">{{ t('Close') }}</v-btn>
            <v-btn color="primary" text @click="submitPaymentForm">{{ t('Save') }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </v-content>
</template>

<script>
import axios from 'axios';

export default {
  name: "bouquetsDetails",
  data() {
    return {
      snack: false,
      snackColor: '',
      snackText: '',
      loading: true,
      loadingbouquets: true,
      loadingAvailableBouquets: true,
      loadingTariffs: false,
      loadingPeriods: false,
      snackbar: false,
      dialog: false,
      search: '',
      text: 'Oops... Something went wrong',
      timeout: 5000,
      details: {},
      deletePackageDialog: false,
      newBalance: '',
      headers: [
        {
          text: this.$translate.locale['Header'],
          align: 'left',
          value: 'name',
        },
        {
          text: this.$translate.locale['Age limit'],
          align: 'left',
          value: 'age_limit',
        },
        {text: this.$translate.locale['Action'], value: 'action', sortable: false},
      ],
      bouquets: [],
      availableBouquets: [],
      rules: {
        required: value => !!value || this.$translate.locale['Required.'],
        counter: value => value <= 128 || this.$translate.locale['Max 128'],
        counterTariff: value => value <= 2147483647 || this.$translate.locale['Max 2147483647'],
        number: value => {
          const pattern = /^(?=.+)(?:[1-9]\d*|0)?(?:\.\d+)?$/;
          return pattern.test(value) || this.$translate.locale['Invalid number.']
        },
      },
      tariff_headers: [
        {
          text: this.$translate.locale['Payment Period Name'],
          align: 'left',
          value: 'name',
        },
        {
          text: this.$translate.locale['Payment Period'],
          value: 'kind_period',
        },
        {
          text: this.$translate.locale['Tariff'],
          value: 'tariff',
        },
        {
          text: this.$translate.locale['Quantity'],
          value: 'quantity',
        },
        {text: this.$translate.locale['Action'], value: 'action', sortable: false},
      ],
      payment_periods_headers: [
        {
          text: this.$translate.locale['Payment Period Name'],
          align: 'left',
          value: 'name',
        },
        {
          text: this.$translate.locale['Payment Period'],
          value: 'kind_period',
        },
        {
          text: this.$translate.locale['Quantity'],
          value: 'quantity',
        },
        {text: this.$translate.locale['Action'], value: 'action', sortable: false},
      ],
      tariffs: [],
      availablePeriods: [],
      deleteDialog: false,
      choosenId: 0,
      tariff: 1,
      onlyAdmins: true,

      headerEdit: false,
      newHeader: '',
      savingNewHeader: false,
    }
  },
  computed: {
    canDelete() {
      // console.log('CAN DELETE?', this.$store.getters.isAdmin || this.$store.getters.isOwner);
      // return this.$store.getters.isAdmin || this.$store.getters.isOwner;
      return this.$store.getters.isOwner;
    }
  },
  methods: {
    getData() {
      this.loadingbouquets = true;
      this.loadingTariffs = true;
      axios.get(`/api/packages/${this.$route.params.id}/`)
          .then((response) => {
            if (response.status === 200) {
              this.details = response.data;
              this.bouquets = this.details.bouquets;
              this.tariffs = this.details.tariffs;
              this.loadingbouquets = false;
              this.loadingTariffs = false;
            }
          }).catch((error) => {
        this.text = "Connection error";
        this.snackbar = true;
      });
      this.loadingAvailableBouquets = true;
      axios.get(`/api/bouquets/${this.$route.params.id}/`)
          .then((response) => {
            if (response.status === 200) {
              this.availableBouquets = response.data;
              this.loadingAvailableBouquets = false;
            }
          }).catch((error) => {
        this.text = "Connection error";
        this.snackbar = true;
      });
      this.loadingPeriods = true;
      axios.get(`/api/payment-periods/filter/${this.$route.params.id}/`)
          .then((response) => {
            if (response.status === 200) {
              this.availablePeriods = response.data;
              this.loadingPeriods = false;
            }
          }).catch((error) => {
        this.text = "Connection error";
        this.snackbar = true;
      });
    },
    addBouquet(pk) {
      axios.put(`/api/packages/edit/bouquets/${this.$route.params.id}/`, {bouquet: pk})
          .then((response) => {
            if (response.status === 200) {
              this.getData();
            }
          }).catch((error) => {
        this.text = "Connection error";
        this.snackbar = true;
      });
    },
    submitPaymentForm() {
      if (!this.$refs.form.validate()) {
        this.text = "Fill the form correctly";
        this.snackbar = true;
      } else {
        const data = {
          tariff: this.tariff,
          payment_period_id: this.choosenId,
          only_admins: this.onlyAdmins
        };
        axios.put(`/api/packages/edit/tariff/${this.$route.params.id}/`, data)
            .then((response) => {
              if (response.status === 200) {
                this.getData();
                this.closeSubmitForm();
                this.text = "Payment period attached";
                this.snackbar = true;
              }
            }).catch((error) => {
          this.text = "Connection error";
          this.snackbar = true;
        });
      }
    },
    showSubmitForm(id, tariff = 1, onlyAdmins = true) {
      this.dialog = true;
      this.choosenId = id;
      this.tariff = tariff;
      this.onlyAdmins = onlyAdmins;
    },
    showRemovalWarning(id) {
      this.deleteDialog = true;
      this.choosenId = id;
    },
    removeTariff() {
      axios.put(`/api/packages/delete/tariff/${this.choosenId}/`)
          .then((response) => {
            if (response.status === 200) {
              this.closeRemovalWarning();
              this.getData();
              this.text = this.$translate.locale['Payment period detached'];
              this.snackbar = true;
            }
          }).catch((error) => {
        this.text = this.$translate.locale["Connection error"];
        this.snackbar = true;
      });
    },
    clear() {
      this.choosenId = 0;
      this.tariff = 1;
      this.onlyAdmins = true;
    },
    closeSubmitForm() {
      this.dialog = false;
      this.clear();
    },
    closeRemovalWarning() {
      this.deleteDialog = false;
      this.clear();
    },
    removeBouquet(pk) {
      axios.put(`/api/packages/delete/bouquets/${this.$route.params.id}/`, {bouquet: pk})
          .then((response) => {
            if (response.status === 200) {
              this.getData();
            }
          }).catch((error) => {
        this.text = "Connection error";
        this.snackbar = true;
      });
    },
    deletePackage() {
      axios.delete(`/api/packages/delete/${this.$route.params.id}/`)
          .then((response) => {
            if (response.status === 204) {
              this.$router.push('/packages')
            }
          }).catch((error) => {
        this.text = "Connection error";
        this.snackbar = true;
      });
    },
    changeBalance() {
      if (!this.$refs.tariffForm.validate()) {
        this.text = "Fill the form correctly";
        this.snackbar = true;
      } else {
        this.loading = true;
        const putBody = {
          tariff: this.newBalance.replace(/,/g, '.')
        };
        axios.put(`/api/packages/edit/tariff/${this.$route.params.id}/`, putBody)
            .then((response) => {
              if (response.status === 200) {
                this.details.tariff = response.data.tariff;
                this.newBalance = this.details.tariff;
                this.loading = false;
                this.dialog = false;
                this.text = "Tariff changed!";
                this.snackbar = true;
              }
            }).catch((error) => {
          if (error.response.status === 400) {
            this.text = "Balance too low";
            this.snackbar = true;
          } else {
            this.text = "Connection error";
            this.snackbar = true;
          }
        });
      }
    },
    saveNewHeader() {
      const putBody = {
        header: this.newHeader,
      };
      this.savingNewHeader = true

      axios.put(`/api/packages/edit/header/${this.$route.params.id}/`, putBody)
        .then((response) => {
          if (response.status === 200) {
            this.details.header = this.newHeader;
            this.newHeader = '';
            this.headerEdit = false;
            this.text = "Header changed!";
            this.snackbar = true;
          }
        }).catch((error) => {
          this.text = "Failed to set new header";
          this.snackbar = true;
        }).finally( () => {
          this.savingNewHeader = false;
        });
    }
  },
  beforeCreate() {
    if (!this.$store.getters.loggedIn) {
      this.$router.push('/')
    }
  },
  mounted() {
    this.getData();

  }
}
</script>

<style scoped>

</style>
